import React, { useState, useEffect } from 'react';

import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import { useIntl } from '../../../../../plugins/publicis-gatsby-plugin-i18n';
import QuoteMark from '../../../../Atoms/QuoteMark/QuoteMark';
import AudioComponent from '../../../../components/AudioComponent/AudioComponent';
import Carousel from '../../../../components/Carousel/Carousel';
import TextVisuelTwoCol from '../../../../components/ContentType/TextVisuelTwoCol/TextVisuelTwoCol';
import PageBannerStyle from '../../../../components/PageBannerStyle/PageBannerStyle';
import Seo from '../../../../components/Seo/Seo';
import SliderComponent from '../../../../components/SliderComponent/SliderComponent';
import Layout from '../../../../Layout';
import { Button } from '../../../../Molecules/Button/Button';
import ShapeBackground from '../../../../Molecules/ShapeBackground/ShapeBackground';
import TitlePage from '../../../../Molecules/TitlePage/TitlePage';
import TitleSection from '../../../../Molecules/TitleSection/TitleSection';
import { getImage, removeHtmlTag, getBreadCrumb, getReOrderedCarouselSlides } from '../../../../Utils/Utils';

import './styles.scss';

let classNames = require('classnames');

const RoutePage = ({ data }) => {
  const intl = useIntl();
  const detailsActivitiesData = data?.detailsActivitiesData?.edges[0]?.node;
  const imagesArray = data?.allImages?.edges;
  const allMenu = data.allMenu.edges;
  const breadCrumb = getBreadCrumb(allMenu, detailsActivitiesData.path?.alias);

  const [allText, setAllText] = useState('');

  let globalText = '';

  const slider_settings = {
    className: 'center',
    centerMode: false,
    infinite: false,
    centerPadding: '100px',
    slidesToShow: 1.5,
    speed: 500,
    initialSlide: 0,
    arrows: false,
    responsive: [{
      breakpoint: 768,
      settings: {
        slidesToShow: 1.05,
        dots: true
      }
    }]
  };

  useEffect(() => {
    setAllText(removeHtmlTag(detailsActivitiesData.body?.processed || '' + '.' + detailsActivitiesData.field_text_2?.processed || '' + globalText || ''));
  }, []);

  return (
    <Layout imagesArray={imagesArray}>
      <Seo
        title={detailsActivitiesData.title}
        description={detailsActivitiesData.body?.processed}
      />
      <div className={classNames('page_template', 'activities_inner_page')}>

        <PageBannerStyle
          visuel={getImage(imagesArray, detailsActivitiesData?.relationships?.field_image?.drupal_internal__mid)?.childImageSharp?.fluid} scroll={true}
          breadcrumbData={{
            //grandParentPage: { title: 'Acceuile', url: '/' },
            gparent: { title: breadCrumb?.grandParent?.name, url: breadCrumb?.grandParent?.link },
            parentPage: { title: breadCrumb?.parent?.name, url: breadCrumb?.parent?.link },
            currentPage: { title: breadCrumb?.current?.name, url: breadCrumb?.current?.link },
            locale: detailsActivitiesData.langcode
          }}
        >
          <div className="wrapper_page">
            <TitlePage
              color="color_white"
              title={detailsActivitiesData.title}
            />

            <div className="col_2_fix">
              <div className="col">
                <div dangerouslySetInnerHTML={{ __html: detailsActivitiesData.body?.processed }}></div>
              </div>
              <div className="col">
                <div dangerouslySetInnerHTML={{ __html: detailsActivitiesData.field_text_2?.processed }}></div>
              </div>
            </div>
            {allText && allText.length > 30 && <AudioComponent
              text={intl.formatMessage({ id: 'detailsActivities.button.listen.label' })}
              textToRead={allText}
            />}

          </div>
        </PageBannerStyle>

        {detailsActivitiesData.relationships?.field_blocs?.map((block, i) => {
          switch (block.__typename) {
            case 'block_content__basic':
              globalText += block.field_title?.processed + '.' + block?.body?.processed;
              return (
                <section className={classNames('section_content', 'section_diverse_activities')} key={i}>
                  <div className="wrapper_page_xs">
                    <TitleSection
                      title={block.field_title?.processed}
                    />

                    <TextVisuelTwoCol
                      orderInverse={block.field_alignment === 'right' ? true : false}
                      extremeRight={block.field_alignment === 'right' ? true : false}
                      extremeLeft={block.field_alignment === 'left' ? true : false}
                      extactHalf={true}
                      alignLeft={false}
                      alignTop={false}
                      visuel={getImage(imagesArray, block?.relationships?.field_image?.drupal_internal__mid)?.childImageSharp}
                      text={block?.body?.processed}
                    />

                  </div>
                </section>
              );
            case 'block_content__block_citation':
              globalText += block?.field_citation + '.';
              return (
                <section className={classNames('section_content')} key={i}>
                  <div className="quotemark_container">
                    <QuoteMark
                      lang={block.langcode}
                      size="m"
                      textWeight="m"
                      text={block?.field_citation}
                    />
                  </div>
                </section>
              );

            case 'block_content__block_slider_nos_activites':
              globalText += block.field_title?.processed + '.' + block.field_subtitle?.processed; 2;
              let slides = [];
              block.relationships?.field_nos_activites?.forEach(slideType => {
                slides.push(
                  {
                    image: getImage(imagesArray, slideType?.relationships?.field_image?.drupal_internal__mid),
                    info: {
                      text: slideType.title,
                      link: '/' + slideType.path?.langcode + slideType.path?.alias,
                      link_label: block.field_link_title
                    }
                  }
                );
              });
              //slides.push(...slides);
              return (
                <section className={classNames('section_content', 'section_our_realisation')}>
                  <ShapeBackground
                    top="left"
                    color="catskill_white"
                    bottomInfinite={true}
                  >
                    <div className="wrapper_page_xs">
                      <TitleSection
                        title={block.field_title?.processed}
                        subtitle={block.field_subtitle?.processed}
                        type="arrow"
                      />
                    </div>

                    {
                      slides.length > 0 && (
                        <div className={classNames('wrapper_page_xs', 'only_desktop')}>
                          <Carousel slides={getReOrderedCarouselSlides(slides)} />
                        </div>
                      )
                    }

                    <div className={classNames('only_mobile_tablet')}>
                      <SliderComponent settings={slider_settings}>
                        {slides.map((slide, i) => (
                          <div key={i}>
                            <div className="only_mobile_tablet">
                              {slide?.image &&
                                <Img
                                  style={{ width: '100%' }}
                                  imgStyle={{ objectFit: 'cover' }}
                                  fluid={{ ...slide.image?.childImageSharp?.mobile, aspectRatio: 0.733333333 }}
                                />
                              }
                            </div>
                            <div className="only_desktop">
                              {slide?.image &&
                                <Img
                                  style={{ height: '100%', width: '100%' }}
                                  imgStyle={{ objectFit: 'contain' }}
                                  fluid={slide.image?.childImageSharp?.desktop?.fluid}
                                />
                              }
                            </div>
                            <div className="info_bleu_box">
                              <p>{slide.info?.text}</p>
                              {slide.info?.link &&
                                <Button
                                  primary={true}
                                  label={slide.info?.link_label}
                                  link={slide.info?.link}
                                />
                              }
                            </div>
                          </div>
                        ))}
                      </SliderComponent>
                    </div>
                  </ShapeBackground>
                </section>
              );
          }
        })}

      </div>
    </Layout>
  );
};

export const query = graphql`
query DetailsActivitiesTemplateQuery($locale: String!, $slug: String!, $imagesID: [Int!]) {
  detailsActivitiesData: allNodeActivites(
    filter: {langcode: {eq: $locale}, path: {alias: {eq: $slug}}}
  ) {
    edges {
      node {
        relationships {
          field_image {
            drupal_internal__mid
          }
          field_blocs {
            __typename
            ... on Node {
              ... on block_content__basic {
                body {
                  processed
                }
                field_alignment
                field_title {
                  processed
                }
                field_no_padding
                relationships {
                  field_image {
                    drupal_internal__mid
                  }
                }
              }
              ... on block_content__block_citation {
                field_citation
                langcode
              }
              ... on block_content__block_slider_nos_activites {
                id
                field_title {
                  processed
                }
                field_subtitle {
                  processed
                }
                field_link_title
                relationships {
                  field_nos_activites {
                    __typename
                    ... on Node {
                      ... on node__activites {
                        path {
                          alias
                          langcode
                        }
                        title
                        relationships {
                          field_image {
                            drupal_internal__mid
                          }
                        }
                      }
                      ... on node__realisations {

                        path {
                          alias
                          langcode
                        }
                        title
                        relationships {
                          field_image {
                            drupal_internal__mid
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        field_text_2 {
          processed
        }
        body {
          processed
        }
        title
        langcode
        path {
          langcode
          alias
        }
      }
    }
  }
  allImages: allMediaImage(
    filter: {langcode: {eq: "fr"}, drupal_internal__mid: {in: $imagesID}}
  ) {
    edges {
      node {
        drupal_internal__mid
        relationships {
          field_media_image {
            localFile {
              publicURL
              childImageSharp {
                fixed(width: 456, height: 456) {
                  ...GatsbyImageSharpFixed
                }
                fluid {
                  ...GatsbyImageSharpFluid
                }
                mobile: fixed(width: 456, height: 456) {
                  ...GatsbyImageSharpFixed
                }
                desktop: fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        langcode
      }
    }
  }

  allMenu :   allMenuLinkContentMenuLinkContent(
    filter: {langcode: {eq: $locale}}
  ) {
    edges {
      node {
        drupal_parent_menu_item
        drupal_id
        langcode
        link {
          url
        }
        menu_name
        title
      }
    }
  }

}
`;

export default RoutePage;
